import { setCurrentStep } from "@/store/slices/dataStepz/dataStepzSlice";
import CarouselSevices from "@/ui/views/share/components/CarouselServices/CarouselSevices";
import React, { useEffect, useState } from "react";
import StepStep from "../../components/Step/StepStep";
import { useDispatch, useSelector } from "react-redux";
import { FaCarRear } from "react-icons/fa6";
import styles from "./styles.module.css";
import image from "./assets/1.jpg";
import image2 from "./assets/2.jpg";

import CardsBuys from "./components/CardsBuy";
import AnyQuestions from "@/ui/views/home/components/anyQuestion";
import { useTranslation } from "react-i18next";
import { GiSchoolBag } from "react-icons/gi";

const StepAdditional = () => {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([1]);
  const [dataStepzTitles, setDataStepzTitles] = useState([]);

  const itemsPerPage = 10;
  const totalPages = 10;

  const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
  const setSpetVariableData = useSelector(
    (state) => state.dataSearchEngine.spetVariableData
  );
  const currentStep = useSelector((state) => state.dataStepz.currentStep);

  const nextHandler = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
    window.scrollTo(0, 0);
  };

  const prevHandler = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
    window.scrollTo(0, 0);
  };

  const [activeStep, setActiveStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([1]);

  const handleStepClick = (step) => {
    if (step <= activeStep + 1) {
      setActiveStep(step);
      if (!completedSteps.includes(step)) {
        setCompletedSteps([...completedSteps, step]);
      }
    }
  };

  useEffect(() => {
    dispatch(setCurrentStep(5));
  }, []);

  useEffect(() => {
    let titles = [];
    if (setSpetVariableData === 0) {
      titles = [ "Especialista", "Clínica","Hotel", "Resumen", "STD"];
    } else if (setSpetVariableData === 1) {
      titles = [ "Especialista", "Clínica", "Vuelo", "Resumen", "STD"];
    } else if (setSpetVariableData === 2) {
      titles = [ "Especialista", "Clínica", "Hotel", "Vuelo", "Resumen", "STD"];
    } else if (setSpetVariableData === 3) {
      titles = ["Especialista", "Clínica", "Vuelo", "Hotel", "Traslado","Resumen", "STD"];
    }
    setDataStepzTitles(titles);
  }, [setSpetVariableData]); 

  const cardsTravels = [
    {
      id: 1,
      title: "Full Day City Tour y Comuna 13",
      description: "Precio para 1 persona desde",
      price: "$ 146.153",
      image: image,
      additionalInfo:
        "Encuentro en el punto acordado para dirigirnos al municipio de Guatapé y el Peñol que son de los principales productores de energía del país aportando aproximadamente",
    },
    {
      id: 2,
      title: "Excursión a Guatapé y El Peñol",
      description: "Precio para 1 persona desde",
      price: "$ 120.000",
      image: image,
      additionalInfo:
        "Encuentro en el punto acordado para dirigirnos al municipio de Guatapé y el Peñol que son de los principales productores de energía del país aportando aproximadamente",
    },
    {
      id: 3,
      title: "Tour de Café y Finca en Jardín",
      description: "Precio para 1 persona desde",
      price: "$ 180.000",
      image: image,
      additionalInfo:
        "Encuentro en el punto acordado para dirigirnos al municipio de Guatapé y el Peñol que son de los principales productores de energía del país aportando aproximadamente",
    },
  ];

  const cardsCars = [
    {
      id: 1,
      title: "Toyota Corolla 2022",
      description: "Precio desde",
      price: "$ 85.000.000",
      image: image2,
      additionalInfo:
        "El Toyota Corolla 2022 combina eficiencia de combustible con tecnología avanzada y un diseño elegante, ideal para ciudad y carretera.",
    },
    {
      id: 2,
      title: "Mazda CX-5 2023",
      description: "Precio desde",
      price: "$ 120.000.000",
      image: image2,
      additionalInfo:
        "La Mazda CX-5 2023 ofrece una experiencia de manejo superior con su motor Skyactiv y un diseño interior de alta calidad.",
    },
    {
      id: 3,
      title: "Chevrolet Onix Turbo 2022",
      description: "Precio desde",
      price: "$ 70.000.000",
      image: image2,
      additionalInfo:
        "El Chevrolet Onix Turbo 2022 destaca por su motor eficiente, conectividad avanzada y un diseño moderno que lo hace ideal para familias.",
    },
  ];

  return (
    <div>
      <section className="homeSectionCarrousel">
        <CarouselSevices />
      </section>

      <div className="maxWidthBuildPackage">
        <div className="itemSectionAll">
          {dataStepzTitles.map((item, index) => (
            <StepStep
              key={index}
              title={item}
              step={index + 1}
              onClick={handleStepClick}
              dataPackage={dataPackage}
            />
          ))}
        </div>

        <div className={styles.contentView}>
          <div className={styles.contentTitle}>
            <FaCarRear size={30} />

            <div className={styles.contentTitleText}>
              <p>Traslados</p>
              Busca el traslado para tu viaje
            </div>
          </div>

          <div className={styles.contentInputs}>
            <div className={styles.contentInput}>
              <label htmlFor="input1">Aeropuerto al que llegas:</label>

              <input type="text" id="input1" />
            </div>

            <div className={styles.contentInput}>
              <label htmlFor="input2">Hora de llegada:</label>

              <input type="time" id="input2" />
            </div>

            <div className={styles.contentInput}>
              <label htmlFor="input3">Hora salida vuelo regreso:</label>

              <input type="time" id="input3" />
            </div>

            <button className={styles.buttonSearch}>Buscar</button>
          </div>

          <div className={styles.contentTitle}>
            <GiSchoolBag size={40} />

            <div className={styles.contentTitleText}>
              <p>Tours (Actividades)</p>
              Tienes 3 días de estadía en Medellín, ¿ya sabes qué vas a hacer?
            </div>
          </div>

          <CardsBuys cards={cardsTravels} />

          <div className={styles.contentTitle}>
            <FaCarRear size={30} />

            <div className={styles.contentTitleText}>
              <p>Alquiler de autos </p>
              Maneja tu tiempo, anda en tu estadia sin preocupaciones.
            </div>
          </div>

          <CardsBuys cards={cardsCars} />
        </div>
      </div>

      <div className={styles.finalBuy}>
        <div className={styles.finalBuyMax}>
          <h2>Tu viaje a cartagena</h2>
          <div className={styles.infoFinalBuy}>
            <div className={styles.infoFinalBuyDescrip}>
              <p>Precio final para 1 persona</p>
              <h2>$ 516.750</h2>
              <p>Incluye impuestos, tasas y cargos</p>
            </div>

            <button className={styles.buttonFinalBuy}>Siguiente</button>
          </div>
        </div>
      </div>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default StepAdditional;

import { useState, useEffect } from "react";
import useJoinScreen from "../../../viewModels/videoSdk/joinScreen.viewModel";
import ActivityIndicator from "../../share/components/activityIndicator";
import { useMeeting, useParticipant } from "@videosdk.live/react-sdk";
import Controls from "../components/controls";
import ParticipantView from "../components/participantView";
import { IoClose } from "react-icons/io5";

import Image from "./assets/meet.png";
import { MdDoNotDisturb } from "react-icons/md";

function JoinScreen(props) {
  const {
    code = "",
    setCode,
    createRoom,
    disableButton,
  } = useJoinScreen(props);

  const {
    meetingId,
    isValidatingRoom,
    isDisabledRoom,
    onMeetingLeave,
    creatingRoom,
  } = props;

  const [joined, setJoined] = useState(null);
  const [closeMeeting, setCloseMeeting] = useState(false);

  const statusList = {
    joined: { value: "JOINED" },
    joining: { value: "JOINING" },
  };

  const { join, leave, participants } = useMeeting({
    onMeetingJoined: () => {
      setJoined("JOINED");
    },

    onMeetingLeft: () => {
      onMeetingLeave();
    },
  });

  const joinMeeting = () => {
    if (code) {
      setJoined(statusList.joining.value);
      join({ meetingId: code });
      setCloseMeeting(true);
    }
  };

  useEffect(() => {
    if (code.length > 7) {
      createRoom(true);
    }
  }, [code, disableButton]);
  const firstParticipantId = [...participants.keys()][0];

  return (
    <div className="videsdk joinScreen">
      <div className="form">
        <div className="card">
          <h2 className="cardTitle">
            Videollamadas y reuniones <br /> Teleconsulta Médica
          </h2>
          <p className="cardText">
            Consulta médica en línea, rápida y segura <br /> desde tu hogar.
          </p>
          <div className="containerJoinInput">
           
            <input
              type="text"
              className="inputJoin"
              placeholder="Ingresa un código"
              value={code}
              onChange={(e) => setCode(e.target.value || "")}
            />

            <button
              onClick={joinMeeting}
              className={`${
                disableButton || !code
                  ? "joinScreenDisableButtonOn"
                  : "joinScreenDisableButtonOff"
              }`}
            >
              <p className="btnText">Unirse</p>
            </button>
          </div>
          {!isValidatingRoom && isDisabledRoom && (
            <p className="errorMeeting">¡Sala no disponible!</p>
          )}
        </div>

        <div
          className={`${
            closeMeeting
              ? "meetingPeopleTeam initial"
              : "meetingPeopleTeam finally"
          }`}
        >
          <div
            className={`${
              closeMeeting
                ? "meetingPeopleTeamContent initial"
                : "meetingPeopleTeamContent finally"
            }`}
          >
            {joined === statusList.joined.value ? (
              <div className="meetingNow">
                <h3>Videollamada en vivo</h3>
                <div className="participantContainer">
                  {[...participants.keys()].map((participantId) => (
                    <ParticipantView
                      participantId={participantId}
                      key={participantId}
                    />
                  ))}
                </div>
                <Controls
                  participantId={firstParticipantId}
                  closeMeeting={closeMeeting}
                  setCloseMeeting={setCloseMeeting}
                  setCode={setCode}
                />
              </div>
            ) : !isValidatingRoom && isDisabledRoom ? (
              <div className="allCenter">
                <p className="onlyText">
                  {isValidatingRoom ? "Sala no encontrada" : "Sala no disponible"}
                  <MdDoNotDisturb size={25} />
                </p>
                <button
                  onClick={() => {
                    leave();
                    setCloseMeeting(false);
                    setCode("");
                  }}
                  className="closeButtonMeeting"
                >
                  Salir
                </button>
              </div>
            ) : (
              joined === statusList.joining.value && (
                <div className="allCenter">
                  {!isDisabledRoom && (
                    <ActivityIndicator size={40} colorFill="#FFFFFF" />
                  )}
                  <p className="onlyText">
                    {!isDisabledRoom
                      ? "Uniéndose a la reunión..."
                      : "Lo siento ocurrio un error"}
                  </p>

                  {isDisabledRoom && (
                    <button
                      onClick={() => {
                        leave();
                        setCloseMeeting(false);
                        setCode("");
                      }}
                      className="closeButtonMeeting"
                    >
                      Salir
                    </button>
                  )}
                </div>
              )
            )}
          </div>
        </div>

        <div className="joinScreenImage">
          <img src={Image} alt="Meet" />
        </div>
      </div>
    </div>
  );
}

export default JoinScreen;

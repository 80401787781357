import React, { useEffect, useRef, useState } from 'react'
import styles from "./styles.module.css"
import SvgIcon, { iconList } from '@/ui/views/share/components/iconSVG'
import Collapsible from '../components/Collapsible/Collapsible';
import ProcedureModal from '../components/ProcedureModal/ProcedureModal';

const FormProcedure = ({
    setCheckboxCheck,
    checkboxCheck,
    camps,
    change,
    setFormData,
    isOpen,
    onOpen,
    formError,
    formData,
    formsFilter, FormErrors, uploadedImages, handleImageUpload, validateForm, index
}) => {


    const [height, setHeight] = useState(0);
    const contentRef = useRef(null);

    useEffect(() => {
        if (contentRef.current) {
            if (isOpen) {
                setHeight(contentRef.current.scrollHeight);  // Establece la altura del contenido cuando está abierto
            } else {
                setHeight(0);  // Colapsa a 0 cuando está cerrado
            }
        }
    }, [isOpen, formData]);


    const handleButtonClick = () => {
        setCheckboxCheck(true);
        console.log("Estado cambiado a true");
    };

    const handleCheckboxChange = (e, key, isCheckbox, procedimiento, camps, contenedor) => {
        change(e, key, isCheckbox, procedimiento, camps);

        // Actualizar el formData con el estado del checkbox
        const updatedFormData = {
            ...formData,
            [procedimiento]: {
                ...formData[procedimiento],
                [key]: e.target.checked
            }
        };
        setFormData(updatedFormData);

        // Verificar si al menos un checkbox está marcado en cada contenedor
        const contenedor1Checked = camps.formulario.slice(0, Math.ceil(camps.formulario.length / 2)).some(campo =>
            campo.type === 'checkbox' && campo.options &&
            campo.options.some(option => updatedFormData?.[camps.procedimiento]?.[option.key])
        );

        const contenedor2Checked = camps.formulario.slice(Math.ceil(camps.formulario.length / 2)).some(campo =>
            campo.type === 'checkbox' && campo.options &&
            campo.options.some(option => updatedFormData?.[camps.procedimiento]?.[option.key])
        );

        // Actualizar estado checkboxCheck
        setCheckboxCheck(contenedor1Checked && contenedor2Checked);
    };  

    return (
        <Collapsible title={camps?.procedimiento} index={index + 2} border={true} open={onOpen} isOpenT={isOpen} id={index}>

            <div ref={contentRef} className={styles.camps_proce}>
                <div className={styles.leftColumn}>
                    {camps?.formulario.slice(0, Math.ceil(camps.formulario.length / 2)).map((campo, idx) => {
                        // Verificar si el campo depende de otro y determinar si debe estar deshabilitado
                        const isDisabled = campo.dependsOn
                            ? formData?.[camps.procedimiento]?.[campo.dependsOn.key] !== campo.dependsOn.value
                            : false;

                        return (
                            <div
                                key={idx}
                                style={{
                                    flexDirection: campo?.type === 'checkbox' && !campo?.options && 'row-reverse',
                                    justifyContent: campo?.type === 'checkbox' && !campo?.options && 'flex-end',
                                    opacity: isDisabled ? 0.5 : 1, // Estilo visual para indicar que está deshabilitado
                                    display: isDisabled ? 'none' : ''
                                }}
                                className={styles.camp}
                            >
                                <label className={styles.labeltitulo}>{campo?.label}<span style={{color:'red'}}> *</span></label>

                                {/* Renderizar el campo según su tipo, con la validación de deshabilitar */}
                                {campo?.type === 'text' && (
                                    <input
                                        type="text"
                                        style={{ borderColor: formError[camps?.procedimiento]?.[campo?.key] && 'var(--redPrimary)' }}
                                        required={campo?.required}
                                        disabled={isDisabled}
                                        onChange={(e) => change(e, campo?.key, false, camps?.procedimiento, camps)}
                                    />
                                )}

                                {campo?.type === 'date' && (
                                    <input
                                        type="date"
                                        required={campo?.required}
                                        disabled={isDisabled}
                                        onChange={(e) => change(e, campo?.key, false, camps?.procedimiento, camps)}
                                    />
                                )}

                                {campo?.type === 'checkbox' && campo?.options && (
                                    <>
                                        {campo?.options?.map(option => (
                                            <div key={option.key} className={styles.checkboxCont}>
                                                <input
                                                    type="checkbox"
                                                    id={option.key}
                                                    disabled={isDisabled}
                                                    onChange={(e) => handleCheckboxChange(e, option.key, true, camps?.procedimiento, camps, 1)}
                                                />
                                                <label htmlFor={option.key} style={{ marginLeft: '5px' }}>
                                                    {option.label}
                                                </label>
                                            </div>
                                        ))}
                                    </>
                                )}



                                {campo?.type === 'checkbox' && !campo?.options && (
                                    <div className={styles.checksDiv}>
                                        <div className={styles.divOptions2}>
                                            <span>
                                                Sí
                                            </span>
                                            <span>
                                                No
                                            </span>
                                        </div>
                                        <div className={styles.checkboxCont3}>
                                            <input
                                                type="radio"
                                                id={`${campo?.key}Yes`}
                                                name={`radio_${campo?.key}`}
                                                disabled={isDisabled}
                                                style={{ borderColor: formError[camps?.procedimiento]?.[campo?.key] && 'var(--redPrimary)' }}
                                                onChange={(e) => change(e, campo?.key, true, camps?.procedimiento, camps)}
                                            />
                                            <input
                                                type="radio"
                                                id={`${campo?.key}No`}
                                                name={`radio_${campo?.key}`}
                                                disabled={isDisabled}
                                                style={{ borderColor: formError[camps?.procedimiento]?.[campo?.key] && 'var(--redPrimary)' }}
                                                onChange={(e) => change(e, campo?.key, false, camps?.procedimiento, camps)}
                                            />
                                        </div>
                                    </div>
                                )}

                                {/* Campo tipo select */}
                                {campo?.type === 'select' && campo?.options && (
                                    <select
                                        required={campo?.required}
                                        disabled={isDisabled}
                                        style={{ borderColor: formError[camps?.procedimiento]?.[campo?.key] && 'var(--redPrimary)' }}
                                        onChange={(e) => change(e, campo?.key, false, camps?.procedimiento, camps)}
                                    >
                                        <option value="">Selecciona una opción</option>
                                        {campo?.options.map((option, key) => (
                                            <option key={key} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                        );
                    })}
                </div>

                <div className={styles.rightColumn}>
                    {camps?.formulario.slice(Math.ceil(camps.formulario.length / 2)).map((campo, idx) => {
                        // Verificar si el campo depende de otro y determinar si debe estar deshabilitado
                        const isDisabled = campo.dependsOn
                            ? formData?.[camps.procedimiento]?.[campo.dependsOn.key] !== campo.dependsOn.value
                            : false;

                        return (
                            <div
                                key={idx}
                                style={{
                                    flexDirection: campo?.type === 'checkbox' && !campo?.options && 'row-reverse',
                                    justifyContent: campo?.type === 'checkbox' && !campo?.options && 'flex-end',
                                    opacity: isDisabled ? 0.5 : 1, // Estilo visual para indicar que está deshabilitado
                                    display: isDisabled ? 'none' : ''
                                }}
                                className={styles.camp}
                            >
                                <label className={styles.labeltitulo}>{campo?.label}<span style={{color:'red'}}> *</span></label>

                                {/* Renderizar el campo según su tipo, con la validación de deshabilitar */}
                                {campo?.type === 'text' && (
                                    <input
                                        type="text"
                                        style={{ borderColor: formError[camps?.procedimiento]?.[campo?.key] && 'var(--redPrimary)' }}
                                        required={campo?.required}
                                        disabled={isDisabled}
                                        onChange={(e) => change(e, campo?.key, false, camps?.procedimiento, camps)}
                                    />
                                )}

                                {campo?.type === 'date' && (
                                    <input
                                        type="date"
                                        required={campo?.required}
                                        disabled={isDisabled}
                                        onChange={(e) => change(e, campo?.key, false, camps?.procedimiento, camps)}
                                    />
                                )}

                                {campo?.type === 'checkbox' && campo?.options && (
                                    <>
                                        {campo?.options?.map(option => (
                                            <div key={option.key} className={styles.checkboxCont}>
                                                <input
                                                    type="checkbox"
                                                    id={option.key}
                                                    disabled={isDisabled}
                                                    onChange={(e) => handleCheckboxChange(e, option.key, true, camps?.procedimiento, camps, 2)}
                                                />
                                                <label htmlFor={option.key} style={{ marginLeft: '5px' }}>
                                                    {option.label}
                                                </label>
                                            </div>
                                        ))}
                                    </>
                                )}



                                {campo?.type === 'checkbox' && !campo?.options && (
                                    <div className={styles.checksDiv}>
                                        <div className={styles.divOptions2}>
                                            <span>
                                                Sí
                                            </span>
                                            <span>
                                                No
                                            </span>
                                        </div>
                                        <div className={styles.checkboxCont3}>
                                            <input
                                                type="radio"
                                                id={`${campo?.key}Yes`}
                                                name={`radio_${campo?.key}`}
                                                disabled={isDisabled}
                                                style={{ borderColor: formError[camps?.procedimiento]?.[campo?.key] && 'var(--redPrimary)' }}
                                                onChange={(e) => change(e, campo?.key, true, camps?.procedimiento, camps)}
                                            />
                                            <input
                                                type="radio"
                                                id={`${campo?.key}No`}
                                                name={`radio_${campo?.key}`}
                                                disabled={isDisabled}
                                                style={{ borderColor: formError[camps?.procedimiento]?.[campo?.key] && 'var(--redPrimary)' }}
                                                onChange={(e) => change(e, campo?.key, false, camps?.procedimiento, camps)}
                                            />
                                        </div>
                                    </div>
                                )}






                                {/* Campo tipo select */}
                                {campo?.type === 'select' && campo?.options && (
                                    <select
                                        required={campo?.required}
                                        disabled={isDisabled}
                                        style={{ borderColor: formError[camps?.procedimiento]?.[campo?.key] && 'var(--redPrimary)' }}
                                        onChange={(e) => change(e, campo?.key, false, camps?.procedimiento, camps)}
                                    >
                                        <option value="">Selecciona una opción</option>
                                        {campo?.options.map((option, key) => (
                                            <option key={key} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div> 

            <div className={styles.containFormTitleItemImages}>
                <div className={styles.formTitleItemImages}>
                    <h4>Fotografías Requeridas ({camps?.procedimiento}) <span style={{color:'red',  fontSize:'17px'}}>*</span> </h4>
                    <p>Estas son las indicaciones para lograr tomar las foto correctamente</p>
                </div>
                <ProcedureModal
                    formsFilter={formsFilter}
                    FormErrors={FormErrors}
                    uploadedImages={uploadedImages}
                    handleImageUpload={handleImageUpload}
                    validateForm={validateForm}
                />

                {formsFilter.map((_, index) => {
                    // Asegúrate de acceder correctamente al `item` correspondiente
                    const item = formsFilter[index]; // Si formsFilter tiene objetos que incluyen `procedimiento`

                    // Contar la cantidad de imágenes cargadas para el procedimiento actual
                    const uploadedCount = uploadedImages[item.procedimiento] ? Object.keys(uploadedImages[item.procedimiento]).length : 0;

                    // Calcular la cantidad de imágenes requeridas restando las imágenes cargadas
                    const requiredImagesCount = item.require_images.length - uploadedCount;

                    // Generar el texto correcto basado en la cantidad de imágenes requeridas
                    const requiredImagesText = requiredImagesCount > 1
                        ? `Para continuar, debes subir ${requiredImagesCount} imágenes.`
                        : `Para continuar, debes subir ${requiredImagesCount} imagen.`;

                    // Condición para mostrar el mensaje en verde si todas las imágenes requeridas están subidas
                    if (requiredImagesCount === 0) {
                        return (
                            <p key={index} style={{ color: "green" }}>
                                Imágenes seleccionadas exitosamente.
                            </p>
                        );
                    }

                    return (
                        FormErrors[item.procedimiento]?.require_images && (
                            <p key={index} style={{ color: "var(--redPrimary)" }}>
                                {requiredImagesText}
                            </p>
                        )
                    );
                })}



            </div>
        </Collapsible>
    )
}

export default FormProcedure
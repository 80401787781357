import React, { useState } from "react";
import useRegisterUser from "../../../viewModels/session/register.user.viewModel";
import "./styles.css";
import SvgIcon, { iconList } from "../../share/components/iconSVG";
import TextCarousel from "../components/TextCarousel/TextCarousel";
import { Link } from "react-router-dom";
import { global } from "../../../global";
import { LuCalendarCheck, LuLoader2 } from "react-icons/lu";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DateInputWithPicker from "../../BuildPackage/pages/std/components/SelectedDate/DateInputWithPicker";
import { formatToDDMMYYYY } from "@/common/services/dateUtils";
import toast, { Toaster } from "react-hot-toast";
import SearchInputNew from "../components/SearchableInput/SearchInputNew";
  
function UserRegister() {
  const {
    inputsList,
    btnDisabled,
    showPassword,
    setShowPassword,
    loading,
    inputErrors,
    handleRegister,
    validateInput,
    setFormValues
  } = useRegisterUser();

  const [birthDate, setBirthDate] = useState("")
  const [validation, setValidation] = useState(false)
  const [selectedBirthDate, setSelectedBirthDate] = useState(null);

  const { BannerTours } = global.img.pictures;
  const {
    logos: { wellezyLogo },
  } = global.img;

  const passwordRequirements = [
    { regex: /[A-Z]/, text: "Debe contener al menos una letra mayúscula" },
    { regex: /[a-z]/, text: "Debe contener al menos una letra minúscula" },
    { regex: /\d/, text: "Debe contener al menos un número" },
    {
      regex: /[!@#$%^&*(),.?":{}|<>]/,
      text: "Debe contener al menos un carácter especial",
    },
    { regex: /^.{8,16}$/, text: "Debe tener entre 8 y 16 caracteres" },
  ];

  const passwordInput = inputsList.find((input) => input.id === "password");

  if (!passwordInput) {
    throw new Error("El campo de contraseña no existe.");
  }

  const isPasswordEmpty = passwordInput.value.trim() === "";

  const isPasswordValid =
    !isPasswordEmpty &&
    passwordRequirements.every((req) => passwordInput.value.match(req.regex));

  const validationResult = !isPasswordValid && isPasswordEmpty;

  const isPasswordValidPass = passwordRequirements.every((req) =>
    inputsList.find((input) => input.id === "password").value.match(req.regex)
  );

  const notify = (title, icon) =>
    toast(title, {
      icon: icon,
    });

  const handleRegisterEvent = async () => {
    try {
      await handleRegister();
    } catch (error) {
      notify(error.message, "❌");
    }
  };

  return (
    <>
      <Toaster position="top-center" style={{ zIndex: 999 }} reverseOrder={false} />
      <div className="userRegister">
        <div className="userRegisterContainer">
          <section className="sectionRight">
            <Link to="/">
              <img
                src={wellezyLogo}
                alt="LogoWellezy"
                className="logo-wellezy-login"
              />
            </Link>
            <div className="sectionRightTextContainer">
              <TextCarousel />
            </div>
            <img src={BannerTours} alt="background" className="background" />
          </section>

          <section className="sectionLeft">
            <div className="sectionLeftLogin">
              <div></div>
              <div className="userRegisterFormContainer">
                <div className="userRegisterFormContainerHead">
                  <h3 className="userRegisterFormTitle">Regístrate</h3>
                </div>
                <div className="userRegisterFormContainerBody">
                  {inputsList.map((input, key) => {
                    const isError = inputErrors[input.id];
                    const showBtn = ["password", "confirmPassword"].includes(
                      input.id
                    );

                    return (
                      <div key={key} className="userRegisterInputContainer">
                        {input.fields && input.type === "flex" ? (
                          <div>
                            {input.fields.map((input, index) => (
                              <div key={index} className="flexRowContainerItem">
                                {input.id === "prex_and_phone_public" && (
                                  <div className="flexRowContainItemInput">
                                    <label className="userRegisterInputLabel">
                                      {input.label}
                                    </label>

                                    <PhoneInput
                                      country={"co"}
                                      value={input.value}
                                      onChange={input.func}
                                      inputStyle={inputErrors[input.id] ? { border: "1px solid #FF5151", width: "100%", borderRadius: "8px"} : {}}
                                      enableSearch={true}
                                    />

                                    {inputErrors[input.id] && (
                                      <p className="userRegisterInputErrorLabel">
                                        {inputErrors[input.id]}
                                      </p>
                                    )}
                                  </div>
                                )}

                                {input.id === "birthDate" && (
                                  <div className="flexRowContainItemInput" style={{marginTop: "10px"}}>
                                    <label className="userRegisterInputLabel" htmlFor="birthDate">
                                      {input.label}
                                    </label>

                                    <DateInputWithPicker
                                      inputId="birthDate"
                                      selectedDate={selectedBirthDate}
                                      setSelectedDate={(date) => {
                                          setSelectedBirthDate(date);
                                  
                                          const formattedDate = date ? formatToDDMMYYYY(date) : "";
                                          setBirthDate(formattedDate);
                                
                                          validateInput("birthDate", formattedDate);
                                  
                                          setFormValues((prevValues) => ({
                                            ...prevValues,
                                            birthDate: formattedDate,
                                          }));
                                      }}
                                      setBirthDate={setBirthDate}
                                      isBirthday={true}
                                      placeholder="dd/mm/yyyy"
                                      icon={() => <LuCalendarCheck color="var(--blueLightTransparent)" size={17} />}
                                      hasButton={false}
                                      validationForm={!!inputErrors["birthDate"]} 
                                    />

                                    {inputErrors["birthDate"] && (
                                      <p className="userRegisterInputErrorLabel">
                                        {inputErrors["birthDate"]}
                                      </p>
                                    )}
                                  </div>
                                )}

                                {input.id === "gender" && (
                                  <div className="flexRowContainItemInput" style={{marginTop: "10px"}}>
                                    <label className="userRegisterInputLabel">
                                      {input.label}
                                    </label>
                                    <SearchInputNew
                                      name={input.id}
                                      options={input.options}
                                      value={input.value}
                                      onChange={input.func}
                                      placeholder={input.placeholder}
                                      onBlur={input.onBlur}
                                      type={input.type}
                                      id={input.id}
                                      inputErrors={inputErrors[input.id]}
                                    />

                                    {inputErrors[input.id] && (
                                      <p className="userRegisterInputErrorLabel">
                                        {inputErrors[input.id]}
                                      </p>
                                    )}
                                  </div>
                                )} 

                              </div>
                            ))}
                          </div>
                        ) : (
                          <>
                            <label className="userRegisterInputLabel">
                              {input.label}
                            </label>
                            <div
                              className={`userRegisterInput ${isError ? "userRegisterInputError" : ""
                                }`}
                            >
                              <input
                                type={
                                  input.id === "password" && showPassword
                                    ? "text"
                                    : input.type
                                }
                                name={input.id}
                                id={input.id}
                                value={input.value}
                                placeholder={input.placeholder}
                                onChange={input.func}
                                className="userRegisterInputInput"
                                onBlur={input.onBlur}
                              />
                              {showBtn && (input.id === "password" ||
                                input.id === "confirmPassword") && (
                                  <div
                                    className="userRegisterInputShowPassword"
                                    onClick={() => setShowPassword(!showPassword)}
                                  >
                                    <SvgIcon
                                      name={
                                        showPassword
                                          ? iconList.IconEyeOn
                                          : iconList.IconEyeOff
                                      }
                                      color="var(--greySecundary)"
                                      size={25}
                                    />
                                  </div>
                                )}
                            </div>
                          </>
                        )}

                        {isError && (
                          <p className="userRegisterInputErrorLabel">{isError}</p>
                        )}
                      </div>
                    );
                  })}

                  <div className="passwordRequirementsContentMessage">
                    {inputsList.some((input) => input.id === "password" ||
                      input.id === "confirmPassword") && (
                        <div
                          className={`${validationResult || isPasswordValidPass
                            ? "passwordRequirements close"
                            : "passwordRequirements open"
                            }`}
                        >
                          <div className="arrowRight"></div>
                          <h4>Requisitos de la Contraseña:</h4>
                          <ul>
                            {passwordRequirements.map((req, index) => (
                              <li key={index}>
                                <SvgIcon
                                  name={
                                    inputsList
                                      .find((input) => input.id === "password" ||
                                        input.id === "confirmPassword")
                                      .value.match(req.regex)
                                      ? iconList.IconCheckSucces
                                      : iconList.IconCloseCircleOutLine
                                  }
                                  color={
                                    inputsList
                                      .find((input) => input.id === "password" ||
                                        input.id === "confirmPassword")
                                      .value.match(req.regex)
                                      ? "var(--greenPrimary)"
                                      : "var(--redPrimary)"
                                  }
                                  size={20}
                                />
                                <span
                                  style={{
                                    color: inputsList
                                      .find((input) => input.id === "password" ||
                                        input.id === "confirmPassword")
                                      .value.match(req.regex)
                                      ? "var(--greenPrimary)"
                                      : "var(--redPrimary)",
                                  }}
                                >
                                  {req.text}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                  </div>
                </div>

                <div className="userRegisterFormContainerFoot">
                  <button
                    onClick={() => !btnDisabled && handleRegisterEvent()}
                    className={`formBtnSend ${btnDisabled ? "disabled" : "formBtnSendAvaliable"
                      }`}
                  >
                    {loading ? (
                      <LuLoader2 size={19} className="loaderLoginRegister" />
                    ) : (
                      <p className="formBtnSendText">Registrar</p>
                    )}
                  </button>
                </div>

                <div className="separation-or">
                  <div className="separation-line"></div>
                  o
                  <div className="separation-line"></div>
                </div>

                <Link className="button-register" to="/login">
                  Inicia sesión
                </Link>


                <div className="links-web-mobile">
                  <Link className="ofertsLinkLogin" to="/">
                    Inicio
                  </Link>
                  <Link className="ofertsLinkLogin" to="/about">
                    Nosotros
                  </Link>
                  <Link className="ofertsLinkLogin" to="/contact">
                    Contacto
                  </Link>
                </div>
              </div>
              <div className="links-web" style={{ top: "18%" }}>
                <Link className="ofertsLinkLogin" to="/">
                  Inicio
                </Link>
                <Link className="ofertsLinkLogin" to="/about">
                  Nosotros
                </Link>
                <Link className="ofertsLinkLogin" to="/contact">
                  Contacto
                </Link>
              </div>
              <div className="item-between"></div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default UserRegister;

import React, { useEffect, useState } from "react";
import HotelResultRoute from "@/Routes/HotelRoute/HotelResultRoute";
import CarouselSevices from "../../../share/components/CarouselServices/CarouselSevices";
import StepStep from "../../components/Step/StepStep";
import AnyQuestions from "@/ui/views/home/components/anyQuestion";
import StepHome from "../../components/Step/StepHome";
import DataSearch from "../../components/DataSearch/DataSearch";

import { useLocation } from "react-router-dom";
import { setCurrentStep, setRoutes } from "@/store/slices/dataStepz/dataStepzSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const StepHotel = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const location = useLocation();

  const [dataStepzTitles, setDataStepzTitles] = useState([]);

  const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
  const setSpetVariableData = useSelector((state) => state.dataSearchEngine.spetVariableData);
  const routes = useSelector((state) => state.dataStepz.routes);

  const [activeStep, setActiveStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([1]);

  const handleStepClick = (step) => {
    if (step <= activeStep + 1) {
      setActiveStep(step);
      if (!completedSteps.includes(step)) {
        setCompletedSteps([...completedSteps, step]);
      }
    }
  };

  useEffect(() => {
      dispatch(setCurrentStep(3));
    

    dispatch(setRoutes({
      ...routes, hotels: {
        route: location.pathname,
        search: location.search
      }
    }))
  }, []);

  useEffect(() => {
    let titles = [];
    if (setSpetVariableData === 0) {
      titles = [ "Especialista", "Clínica","Hotel", "Resumen", "STD"];
    } else if (setSpetVariableData === 1) {
      titles = [ "Especialista", "Clínica", "Vuelo", "Resumen", "STD"];
    } else if (setSpetVariableData === 2) {
      titles = [ "Especialista", "Clínica", "Hotel", "Vuelo", "Resumen", "STD"];
    } else if (setSpetVariableData === 3) {
      titles = ["Especialista", "Clínica", "Vuelo", "Hotel", "Traslado","Resumen", "STD"];
    }
    setDataStepzTitles(titles);
  }, [setSpetVariableData]); 

  return (
    <div>
      <section className="homeSectionCarrousel">
        <CarouselSevices />
      </section>

      <div className="maxWidthBuildPackage">
        <div className="itemSectionAll">
          <StepHome />
          {dataStepzTitles.map((item, index) => (
              <StepStep
                key={index}
                title={item}
                step={index + 1}
                onClick={handleStepClick}
                dataPackage={dataPackage}
              />
            ))}
        </div>

        <DataSearch />

        <HotelResultRoute />
      </div>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default StepHotel;

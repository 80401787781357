import { useState, useEffect } from "react";
import axios from "axios";
import { formatDateToISO, parseYYYYMMDDToDate } from "@/common/services/dateUtils";

const regex = {
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    name: /^[A-Za-z\s']+$/,
    password: /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,16}$/,
};

const useUserUpdate = () => {
    const [formData, setFormData] = useState({
        id: "",
        birth_date: "",
        gender: "",
        current_password: "",
        name: "",
        email: "",
        prex_and_phone_public: "",
        phone_prefix: "",
        phone_public: "",
        password: "",
        confirm_password: "",
    });


    const [responseMessage, setResponseMessage] = useState("");
    const [responseStatus, setResponseStatus] = useState("");
    const [token, setToken] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState("");

    useEffect(() => {
        const savedData = localStorage.getItem("@authUserSession");

        if (savedData) {
            try {
                const parsedData = JSON.parse(savedData);
                if (parsedData.token && parsedData.user) {
                    setToken(parsedData.token);

                    const birthDate = parsedData.user.birth_date ? parseYYYYMMDDToDate(parsedData.user.birth_date) : null;

                    setFormData({
                        id: parsedData.user.id || "",
                        birth_date: birthDate,
                        gender: parsedData.user.gender || "",
                        current_password: "",
                        name: parsedData.user.name || "",
                        email: parsedData.user.email || "",
                        prex_and_phone_public: parsedData.user.prex_phone_public || "",
                        phone_public: parsedData.user.phone_public || "",
                        phone_prefix: parsedData.user.prex_phone_public || "",
                        password: "",
                        confirm_password: "",
                    });
                }
            } catch (error) {
                console.error("Error al parsear los datos de localStorage", error);
            }
        }
    }, []);

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name.trim()) newErrors.name = "El nombre no puede estar vacío";
        else if (!regex.name.test(formData.name)) newErrors.name = "El nombre solo puede contener letras y espacios";

        if (!formData.email.trim()) newErrors.email = "El email no puede estar vacío";
        else if (!regex.email.test(formData.email)) newErrors.email = "El email debe tener un formato válido";

        if (!formData.prex_and_phone_public.trim()) newErrors.prex_and_phone_public = "El prefijo y el número de teléfono no pueden estar vacíos";

        if (!formData.gender.trim()) newErrors.gender = "Debes seleccionar tu sexo";

        if (!formData.password.trim()) newErrors.password = "La contraseña no puede estar vacía";
        else if (!regex.password.test(formData.password)) newErrors.password = "La contraseña debe tener entre 8 y 16 caracteres, una mayúscula, un número y un símbolo especial";
        else if (formData.password !== formData.confirm_password) newErrors.confirm_password = "Las contraseñas no coinciden"; // Validación de contraseñas iguales

        if (!formData.current_password.trim()) newErrors.current_password = "Debes ingresar tu contraseña actual";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const validateField = (name, value) => {
        const newErrors = { ...errors };

        switch (name) {
            case "name":
                if (!value.trim()) newErrors.name = "El nombre no puede estar vacío";
                else if (!regex.name.test(value)) newErrors.name = "El nombre solo puede contener letras y espacios";
                else delete newErrors.name;
                break;
            case "email":
                if (!value.trim()) newErrors.email = "El email no puede estar vacío";
                else if (!regex.email.test(value)) newErrors.email = "El email debe tener un formato válido";
                else delete newErrors.email;
                break;
            case "prex_and_phone_public":
                if (!value.trim()) newErrors.prex_and_phone_public = "Debes ingresar un prefijo y un número de teléfono";
                else delete newErrors.prex_and_phone_public;
                break;
            case "gender":
                if (!value.trim()) newErrors.gender = "Debes seleccionar tu sexo";
                else delete newErrors.gender;
                break;
            case "password":
                if (!value.trim()) newErrors.password = "La contraseña no puede estar vacía";
                else if (!regex.password.test(value)) newErrors.password = "La contraseña debe tener entre 8 y 16 caracteres, una mayúscula, un número y un símbolo especial";
                else delete newErrors.password;
                break;
            case "confirm_password":
                if (value !== formData.password) newErrors.confirm_password = "Las contraseñas no coinciden";
                else delete newErrors.confirm_password;
                break;
            case "current_password":
                if (!value.trim()) newErrors.current_password = "Debes ingresar tu contraseña actual";
                else delete newErrors.current_password;
                break;
            default:
                break;
        }

        setErrors(newErrors);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        validateField(name, value);
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        validateField(name, value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!token) {
            setResponseMessage("Error: Token no encontrado");
            setMessageType("error");
            return;
        }

        if (!validateForm()) {
            setResponseMessage("Por favor corrige los errores antes de enviar");
            setMessageType("warning");
            return;
        }

        setLoading(true); // Inicia el estado de carga

        try {
            const phoneNumber = formData.prex_and_phone_public.slice(formData.phone_prefix.length); // Remove the prefix from the phone number to send them separately

            const body = {
                id: formData.id,
                name: formData.name,
                email: formData.email,
                password: formData.password,
                phone_public: phoneNumber,
                prex_phone_public: formData.phone_prefix,
                birth_date: formatDateToISO(formData.birth_date),
                gender: formData.gender,
                current_password: formData.current_password,
                confirm_password: formData.confirm_password,
            };

            const response = await axios.post(
                "https://staging.aiop.com.co/api/edit/user",
                body,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            setResponseStatus(response);

            if (response.status === 200) {
                setResponseMessage("Usuario actualizado exitosamente");
                setMessageType("success");
                setErrors({}); // Limpia los errores si la actualización fue exitosa
            }

        } catch (error) {
            if (error.response && error.response.data) {
                // Manejo de errores de respuesta de la API
                if (error.response.data.errors) {
                    const apiErrors = error.response.data.errors;
                    const newErrors = {};

                    // Errores específicos para campos
                    if (apiErrors.current_password) {
                        newErrors.current_password = apiErrors.current_password;
                    }
                    if (apiErrors.password) {
                        newErrors.password = apiErrors.password;
                    }

                    setErrors(prevErrors => ({
                        ...prevErrors,
                        ...newErrors
                    }));
                }

                setResponseMessage("Error en la actualización: " + (error.response.data.errors.password || "Error desconocido"));
                setMessageType("error");
            } else {
                setResponseMessage("Error al actualizar usuario: " + (error.response?.data?.errors.password || "Error desconocido"));
                setMessageType("error");
            }
        } finally {
            setLoading(false);
        }
    };

    return {
        responseStatus,
        formData,
        errors,
        responseMessage,
        messageType,
        loading,
        handleChange,
        handleBlur,
        handleSubmit,
        setFormData,
        validateField
    };
};

export default useUserUpdate;

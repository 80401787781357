import React, { useEffect, useState } from "react";
import "./styles.css";
import { IoLocationOutline } from "react-icons/io5";

import CarouselSevices from "../../../share/components/CarouselServices/CarouselSevices";
import SearchBuildPackage from "../../components/SearchBuildPackage/SearchBuildPackage";
import Paginado from "../../../Hotel/HotelResult/Paginado/Paginado";
import { LiaStarSolid } from "react-icons/lia";
import { FaFilter } from "react-icons/fa";
import { TiArrowSortedDown } from "react-icons/ti";
import StepStep from "../../components/Step/StepStep";
import { Link, useLocation } from "react-router-dom";
import Specialists from "@/ui/views/Specialist/Specialists";
import ReturnPage from "@/ui/views/share/components/return/ReturnPage";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStep, setRoutes } from "@/store/slices/dataStepz/dataStepzSlice";
import { useTranslation } from "react-i18next";
import AnyQuestions from "@/ui/views/home/components/anyQuestion";
import HomeButtonBuildPackage from "../../components/HomeButtonBuildPackage/HomeButtonBuildPackage";
import StepHome from "../../components/Step/StepHome";
import DataSearch from "../../components/DataSearch/DataSearch";

const StepSpecialist = () => {
  const dispatch = useDispatch();
  const location = useLocation(); 

  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([1]);
  const [dataStepzTitles, setDataStepzTitles] = useState([]);
  const [t] = useTranslation("global");

  const itemsPerPage = 10;
  const totalPages = 10;

  const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
  const setSpetVariableData = useSelector((state) => state.dataSearchEngine.spetVariableData);
  const currentStep = useSelector((state) => state.dataStepz.currentStep);
  const routes = useSelector((state) => state.dataStepz.routes);

  const nextHandler = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
    window.scrollTo(0, 0);
  };

  const prevHandler = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
    window.scrollTo(0, 0);
  };

  const [activeStep, setActiveStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([1]);

  const handleStepClick = (step) => {
    if (step <= activeStep + 1) {
      setActiveStep(step);
      if (!completedSteps.includes(step)) {
        setCompletedSteps([...completedSteps, step]);
      }
    }
  };

  useEffect(() => {

      dispatch(setCurrentStep(1));
    

    dispatch(setRoutes({
      ...routes, specialist: {
        route: location.pathname,
        search: location.search
      }
    }))
  }, []);

  useEffect(() => {
    let titles = [];
    if (setSpetVariableData === 0) {
      titles = [ "Especialista", "Clínica","Hotel", "Resumen", "STD"];
    } else if (setSpetVariableData === 1) {
      titles = [ "Especialista", "Clínica", "Vuelo", "Resumen", "STD"];
    } else if (setSpetVariableData === 2) {
      titles = [ "Especialista", "Clínica", "Hotel", "Vuelo", "Resumen", "STD"];
    } else if (setSpetVariableData === 3) {
      titles = ["Especialista", "Clínica", "Vuelo", "Hotel", "Traslado","Resumen", "STD"];
    }
    setDataStepzTitles(titles);
  }, [setSpetVariableData]); 

  return (
    <div>
      <section className="homeSectionCarrousel">
        <CarouselSevices />
      </section>

      <div className="maxWidthBuildPackage">
        <div className="itemSectionAll">
          <StepHome />
          {dataStepzTitles.map((item, index) => (
            <StepStep
              key={index}
              title={item}
              step={index + 1}
              onClick={handleStepClick}
              dataPackage={dataPackage}
            />
          ))}
        </div>
        <DataSearch />
        
        <Specialists />
      </div>      
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default StepSpecialist;
import { Grid } from '@mui/material';
import styles from "./index.module.css";
import { GoStarFill } from 'react-icons/go';
import { useState } from 'react';
import { MdCameraAlt } from 'react-icons/md';

const formatDate = (dateString) => {
    if (!dateString) return '';
    const [month, day, year] = dateString.split('/');
    return `${month}/${day}/${year.slice(-2)}`;
};


const Hotel = ({ dataPackage }) => {

    const stars = parseInt(dataPackage?.hotel?.stars, 10);

    const [loadedImages, setLoadedImages] = useState({});
    const [imageErrors, setImageErrors] = useState({});

    const handleImageLoad = (key) => {
        setLoadedImages(prevState => ({
            ...prevState,
            [key]: true
        }));
        setImageErrors(prevState => ({
            ...prevState,
            [key]: false
        }));
    };

    const handleImageError = (key) => {
        setImageErrors(prevState => ({
            ...prevState,
            [key]: true
        }));
    };

    const imageKey = 'hotelImage'; // Clave única para la imagen del hotel

    return (
        <Grid container spacing={2} className={styles.mainHotelDiv}>
            <Grid item xs={12} md={3} className={styles.imageHotelDiv}>
                {(!loadedImages[imageKey] || imageErrors[imageKey]) && (
                    <div className='img-transport-skeleton-cards'>
                        <MdCameraAlt size={40} color="#c8c8c8" />
                    </div>
                )}
                <img
                    src={dataPackage?.hotel?.dataHab?.foto}
                    alt=""
                    onLoad={() => handleImageLoad(imageKey)}
                    onError={() => handleImageError(imageKey)}
                    style={{ display: loadedImages[imageKey] && !imageErrors[imageKey] ? 'block' : 'none' }}
                />
            </Grid>
            <Grid item xs={12} md={6} className={styles.DataHotelDiv} sx={{ display: 'flex', flexDirection: 'column' }}>
                <div className={styles.topInfoHotel} >
                    <h3>{dataPackage.hotel?.dataHot?.nombre_h} </h3>
                    <h4>{dataPackage?.hotel?.dataHab?.dir}</h4>
                </div>

                <div className={styles.divInfoBottomHotel} >
                    <div className={styles.divHotelData} >
                        <h4>Fecha de ingreso</h4>
                        <p>{formatDate(dataPackage?.hotel?.arrival)}</p>
                    </div>
                    <div className={styles.divHotelData} >
                        <h4>Check In</h4>
                        <p>{dataPackage.hotel?.dataHot?.checkin.replace('-', '')}</p>
                    </div>
                    <div className={styles.divHotelData} >
                        <h4>Fecha de salida</h4>
                        <p>{formatDate(dataPackage?.hotel?.departure)}</p>
                    </div>
                    <div className={styles.divHotelData} >
                        <h4>Check Out</h4>
                        <p>{dataPackage.hotel?.dataHot?.checkout}</p>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={3} className={styles.starsHotelDivMain} sx={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }} >
                <div className={styles.starsHotelDiv} >
                    {[...Array(5)].map((_, index) => (
                        <GoStarFill
                            key={index}
                            className={index < stars ? styles.activeStarHotel : styles.inactiveStarHotel}
                        />
                    ))}
                </div>

                <div className={styles.bottonsDetailsHotel} >
                    <button>Ver más detalles</button>
                    <button>Ver en mapa</button>
                </div>
            </Grid>
        </Grid>
    );
};

export default Hotel;
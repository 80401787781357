export const capitalizeFirstLetter = (string) => {
    try {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } catch (error) {
        throw new Error(error);
    }
}

export const capitalizeFirstLetterOfArray = (array) => {
    try {
        return array.map(item => capitalizeFirstLetter(item));
    } catch (error) {
        throw new Error(error);
    }
}
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { setNationalityData } from "@/store/slices/dataEnginePackage/dataEnginePackageSlice";
import styles from "./styles.module.css";
import { RiFlag2Line } from "react-icons/ri";

const InputNacionalityStd = ({
  nacionalityDropdown,
  setNacionalityDropdown,
  validationForm,
  placeholders,
  codeNacionality = false,
  findCountryClient,
  loading,
  dataNacionality,
  selectedNacionality,
}) => {
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const nationalityData = useSelector(
    (state) => state.dataSearchEngine.nationalityData
  );

  // Usar useState para controlar el valor de nacionality
  const [nacionality, setNacionality] = useState(null);

  const toCamelCaseWithFirstUpper = (str) => {
    if (!str) return "";
    const camelCaseStr = str
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());
    return camelCaseStr.charAt(0).toUpperCase() + camelCaseStr.slice(1);
  };


  // Actualiza nacionality cuando cambia nationalityData
  useEffect(() => {
    setNacionality(nationalityData);
  }, [nationalityData]);

  useEffect(() => {
    if (nacionalityDropdown && inputRef.current) {
      inputRef.current.focus();
    }
  }, [nacionalityDropdown]);

  useEffect(() => {
    dispatch(setNationalityData(placeholders));
  }, [placeholders, dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setNacionalityDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setNacionalityDropdown]);

  console.log(nacionality);
  

  return (
    <div
    ref={containerRef}
      className={styles.container}
      onClick={() => setNacionalityDropdown(!nacionalityDropdown)}
    >
      <div className={styles.inputWrapper}>
        <p
          className={styles.label}
        >
          Nacionalidad <span style={{color:'red'}}>*</span>
        </p>
        <div className={`${styles.inputIcon} ${!nacionality && validationForm ? styles.errorInput : ""
            }`}>
        <input
          type="text"
          placeholder="Elige un país"
          ref={inputRef}
          value={nacionality === "" ? "" : toCamelCaseWithFirstUpper(nacionality)}
          
          onChange={(e) => setNacionality(e.target.value)}
          onInput={(e) => findCountryClient(e.target.value)}
        />
        {loading ? <CircularProgress size={17} className={styles.loader} /> : <RiFlag2Line size={17} className={styles.loader} />}
        </div>
        
      </div>

      {nacionalityDropdown && (
        <div className={styles.dropdown}>
          <ul className={styles.dropdownList}>
            {dataNacionality.map((item, key) => (
              <li
                key={key}
                className={styles.dropdownItem}
                onClick={() => {
                  selectedNacionality(item.code, item.name);
                  setNacionalityDropdown(false);
                  setNacionality(item.name); // Actualiza el valor seleccionado
                }}
              >
                {item.name}, {item.code}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default InputNacionalityStd;

import './styles.css';
import BrowseHome from "../browseHome";
function PrimarySection({pathname}) { 
 
  return (
    <section className='homePrimarySection'>
      <BrowseHome pathname={pathname}/> 
    </section>
  )
}

export default PrimarySection;

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { LuCalendarPlus } from 'react-icons/lu'
import { BiSolidRightArrow } from 'react-icons/bi';
import axios from 'axios'

import useHeaderSession from '@/ui/viewModels/session/header.session.viewModel'
import CarouselSevices from '@/ui/views/share/components/CarouselServices/CarouselSevices'
import { formatToISO, formatYYYYMMDDToDDMMYYYY } from '@/common/services/dateUtils'
import { setCurrentStep, setDataPackage, setLastSearch, setProceduresData, setRoutes, } from "@/store/slices/dataStepz/dataStepzSlice";

import styles from "./styles.module.css"

import Loading from '@/ui/views/share/components/loading'
import StepHome from "../../components/Step/StepHome";
import StepStep from "../../components/Step/StepStep";
import DataSearch from '../../components/DataSearch/DataSearch'
import ModalAction from "../../components/ModalAction/ModalAction";
import SessionModal from '@/ui/views/session/components/SessionModal/SessionModal'
import AnyQuestions from '@/ui/views/home/components/anyQuestion'
import AllCampsForms from './AllCampsForm'
import FormProcedure from './formProcedure/FormProcedure'
import InputNacionalityStd from './components/InputNacionality/InputNacionalityStd'
import DateInputWithPicker from './components/SelectedDate/DateInputWithPicker'

const Std = () => {
    const { userDetail } = useHeaderSession()
    const navigate = useNavigate()
    const [activeFormIndex, setActiveFormIndex] = useState(null);
    const [activeSession, setActiveSession] = useState(false)
    const [paceholders, setPaceholders] = useState("");
    const [nacionality, setNacionality] = useState("");
    const [nacionalityDropdown, setNacionalityDropdown] = useState(false);
    const [dataNacionality, setDataNacionality] = useState([]);
    const [loadinNa, setLoadinNa] = useState(false)

    const {
        cirugiasConfig,
        manejarCambio,
        formData,
        setFormData,
        obtenerFormulariosPorTexto,
        obtenerEstadoInicial,
        FormErrors,
        setFormErrors
    } = AllCampsForms()



    const [eps, setEps] = useState("")
    const [height, setHeight] = useState("")
    const [weight, setWeight] = useState("")
    const [phone, setPhone] = useState(null)
    const [prefixPhone, setPrefixPhone] = useState(null)
    const [typeProcedure, setTypeProcedure] = useState("")
    const [haveChild, setHaveChild] = useState(false)
    const [quantityChild, setQuantityChild] = useState("")
    const [drinkAlcohol, setDrinkAlcohol] = useState(false)
    const [smoke, setSmoke] = useState(false)
    const [sons, setSons] = useState(false)
    const [ages, setAges] = useState("")
    const [showAges, setShowAges] = useState(true);
    const [haveSurgery, setHaveSurgery] = useState(false)
    const [whichSurgery, setWhichSurgery] = useState("")
    const [haveIllness, setHaveIllness] = useState(false)
    const [whichIllness, setWhichIllness] = useState("")
    const [drugsConsume, setDrugsConsume] = useState(false)
    const [whichDrugs, setWhichDrugs] = useState("")
    const [consumeMedicaments, setConsumeMedicaments] = useState(false)
    const [whichMedicaments, setWhichMedicaments] = useState("")
    const [youAlergicKnowed, setYouAlergicKnowed] = useState(false)
    const [whichAlergicKnowed, setWhichAlergicKnowed] = useState("")
    const [youAlergic, setYouAlergic] = useState(false)
    const [whichAlergic, setWhichAlergic] = useState("")
    const [validation, setValidation] = useState(false)
    const [terms, setTerms] = useState(false)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [operationDate, setOperationDate] = useState("")

    const [procedures, setProcedures] = useState([])

    const [t] = useTranslation("global");
    const dispatch = useDispatch();
    const location = useLocation();

    const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
    const dataSearch = useSelector((state) => state.dataSearchEngine);
    const especialtieAvliable = useSelector((state) => state.dataStepz.especialtieAvliable);

    const setSpetVariableData = useSelector((state) => state.dataSearchEngine.spetVariableData);
    const idEspecialtieData = useSelector((state) => state.dataSearchEngine.idEspecialtieData);
    const procedureData = useSelector((state) => state.dataSearchEngine.procedureData);

    const formsFilter = obtenerFormulariosPorTexto(procedureData?.name)

    const routes = useSelector((state) => state.dataStepz.routes);

    const [dataStepzTitles, setDataStepzTitles] = useState([]);
    const [activeStep, setActiveStep] = useState(1);
    const [completedSteps, setCompletedSteps] = useState([1]);

    const [checkboxCheck, setCheckboxCheck] = useState(false);

    const handleStepClick = (step) => {
        if (step <= activeStep + 1) {
            setActiveStep(step);
            if (!completedSteps.includes(step)) {
                setCompletedSteps([...completedSteps, step]);
            }
        }
    };

    useEffect(() => {
        if (setSpetVariableData === 2) {
            dispatch(setCurrentStep(6));
        } else {
            dispatch(setCurrentStep(5))
        }

        dispatch(
            setRoutes({
                ...routes,
                std: {
                    route: location.pathname,
                    search: location.search,
                },
            })
        );
        const url = `${process.env.REACT_APP_URL_API_FINANMED}/category/sub/47`
        axios.get(url)
            .then((res) => {
                setProcedures(res.data)
            })
            .catch(err => {
                console.error(err)
            })

        if (!userDetail) {
            setActiveSession(true)
        }


    }, []);

    useEffect(() => {
        const inicialFormData = {};

        formsFilter.forEach(item => {
            const { data, pro } = obtenerEstadoInicial(item);
            inicialFormData[pro] = data;
        });

        setFormData(inicialFormData);
    }, []);

    useEffect(() => {
        let titles = [];
        if (setSpetVariableData === 0) {
            titles = [ "Especialista", "Clínica","Hotel", "Resumen", "STD"];
          } else if (setSpetVariableData === 1) {
            titles = [ "Especialista", "Clínica", "Vuelo", "Resumen", "STD"];
          } else if (setSpetVariableData === 2) {
            titles = [ "Especialista", "Clínica", "Hotel", "Vuelo", "Resumen", "STD"];
          } else if (setSpetVariableData === 3) {
            titles = ["Especialista", "Clínica", "Vuelo", "Hotel", "Traslado","Resumen", "STD"];
          }
        setDataStepzTitles(titles);
    }, [setSpetVariableData]);

    const sendStd = async () => {
        try {
            setLoading(true)

            const body = {
                eps: eps,
                height: height,
                weight,
                number_children: sons ? quantityChild : 0,
                previous_surgery: haveSurgery ? whichSurgery : "No",
                major_disease: haveIllness ? whichIllness : "No",
                drugs: drugsConsume ? whichDrugs : "No",
                drink_medication: consumeMedicaments ? whichMedicaments : "No",
                allergic_medication: youAlergic ? whichAlergic : "No",
                type_procedure: typeProcedure,
                smoke: smoke ? "Si" : "No",
                alcohol: drinkAlcohol ? "Si" : "No",
                name: userDetail.name,
                email: userDetail.email,
                nacionality: nacionality,
                date_operation: formatToISO(operationDate),
                birth_date: userDetail?.birth_date,
                form: formData,
                // New fields
                specialtie_id: idEspecialtieData,
                clinic_id: dataPackage.clinic?.id || null,
                procedure_id: procedureData?.id || null,
                user_id: userDetail?.id || null,
                flight: dataPackage.flight
                    ? {
                        company: dataPackage.flight.company,
                        currency: dataPackage.flight.currency,
                        itinerary: dataPackage.flight.itinerary.map(itinerary => ({
                            dateArrival: itinerary.dateArrival || '',
                            dateDeparture: itinerary.dateDeparture || '',
                            from: itinerary.from || '',
                            to: itinerary.to || '',
                            company: itinerary.company || '',
                            family: itinerary.family || '',
                        })),
                        price: dataPackage.flight.priceTotal,
                    }
                    : null,
                hotel: dataPackage.hotel
                    ? {
                        arrival: dataPackage.hotel.arrival,
                        departure: dataPackage.hotel.departure,
                        checkIn: dataPackage.hotel.dataHot.checkIn,
                        checkOut: dataPackage.hotel.dataHot.checkOut,
                        country: dataPackage.hotel.country,
                        countryClient: dataPackage.hotel.countryClient,
                        hotel: dataPackage.hotel.hotel,
                        price: dataPackage.hotel.price,
                        stars: dataPackage.hotel.stars,
                    }
                    : null,
            }
    
            if (
                !height ||
                !weight ||
                (sons === true && !ages) ||
                (haveSurgery === true && !whichSurgery) ||
                (haveIllness === true && !whichIllness) ||
                (drugsConsume === true && !whichDrugs) ||
                (consumeMedicaments === true && !whichMedicaments) ||
                (youAlergicKnowed === true && !whichAlergicKnowed) ||
                (youAlergic === true && !whichAlergic) ||
                !terms ||
                !operationDate ||
                !validateForm()
            ) {
                setValidation(true)
                validateForm()
            } else {
                setValidation(false)
                let bodyPackage = {};

                if (especialtieAvliable) {
                    bodyPackage = {
                        specialtie_id: idEspecialtieData,
                        clinic_id: dataPackage.clinic.id,
                        procedure_id: procedureData.id,
                        name: userDetail.name,
                        email: userDetail.email,
                        phone: `+${prefixPhone} ${phone}`,
                    }

                    if (dataPackage.flight) {
                        let itinerary = [];

                        let itineraryFrom = {
                            dateArrival: dataPackage?.flight?.itinerary?.[0]?.dateArrival || '',
                            dateDeparture: dataPackage?.flight?.itinerary?.[0]?.dateDeparture || '',
                            from: dataPackage?.flight?.itinerary?.[0]?.from || '',
                            to: dataPackage?.flight?.itinerary?.[0]?.to || '',
                            company: dataPackage?.flight?.itinerary?.[0]?.company || '',
                            family: dataPackage?.flight?.itinerary?.[0]?.family || ''
                        };

                        itinerary = [itineraryFrom]

                        if (dataPackage?.flight?.itinerary?.length > 1) {
                            let itineraryTo = {
                                dateArrival: dataPackage.flight.itinerary[1]?.dateArrival || '',
                                dateDeparture: dataPackage.flight.itinerary[1]?.dateDeparture || '',
                                from: dataPackage.flight.itinerary[1]?.from || '',
                                to: dataPackage.flight.itinerary[1]?.to || '',
                                company: dataPackage.flight.itinerary[1]?.company || '',
                                family: dataPackage.flight.itinerary[1]?.family || ''
                            };

                            itinerary = [...itinerary, itineraryTo];
                        }

                        let flight = {
                            company: dataPackage.flight.company,
                            currency: dataPackage.flight.currency,
                            itinerary,
                            price: dataPackage.flight.priceTotal,
                        }

                        bodyPackage.flight = flight
                    }

                    if (dataPackage.hotel) {
                        let hotel = {
                            arrival: dataPackage.hotel.arrival,
                            departure: dataPackage.hotel.departure,
                            checkIn: dataPackage.hotel.dataHot.checkIn,
                            checkOut: dataPackage.hotel.dataHot.checkOut,
                            country: dataPackage.hotel.country,
                            countryClient: dataPackage.hotel.countryClient,
                            hotel: dataPackage.hotel.hotel,
                            price: dataPackage.hotel.price,
                            stars: dataPackage.hotel.stars,
                        }

                        bodyPackage.hotel = hotel
                    }
                }

                // TODO: Eliminar URLs del código y pasarlas al archivo .env como una variable
                const urlPackage = `https://crmedic.pdtclientsolutions.com/api/wellezy/store/package`

                const url = `${process.env.REACT_APP_URL_API_FINANMED}/store/std`
                await axios.post(url, body)

                if (especialtieAvliable) {
                    await axios.post(urlPackage, bodyPackage)
                    sendDataAiop()
                } else {
                    sendDataAiop()
                }

                setOpen(true)
            }
        } catch (error) {
            setLoading(false)
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const sendDataAiop = async () => {
        try {
            const url = `${process.env.REACT_APP_URL_API_AIOP}api/searchs/build/package`

            const body = {
                "user_id": userDetail?.id,
                "type_search": dataSearch?.spetVariableData == 0 ? "Hotel" : dataSearch?.spetVariableData == 1 ? "Vuelo" : dataSearch?.spetVariableData == 2 ? "Vuelo + Hotel" : "",
                "origin": dataSearch.originSelectedFlightData,
                "destination": dataSearch.cityHotelData,
                "dep_date": dataSearch.dateGoingSelectedData,
                "arr_date": dataSearch.dateReturnSelectedData
            }

            await axios.post(url, body)
            setLoading(false)
            dispatch(setLastSearch({}))
            dispatch(setDataPackage({}))
            dispatch(setRoutes({}))
            dispatch(setProceduresData([]))
        } catch (error) {
            console.error(error)
            throw new Error(error)
        }
    }

    const closeModal = (moda) => {
        navigate("/")
    }

    useEffect(() => {
        if (!userDetail) {
            setActiveSession(true)
        } else {
            setActiveSession(false)
        }
    }, [userDetail])

    const findCountryClient = (country) => {
        setLoadinNa(true);
        const url = `${process.env.REACT_APP_URL_API_FLY}restel/country`;
        axios
            .post(url, {
                code: country,
            })
            .then((res) => {
                setDataNacionality(res.data);
                setLoadinNa(false);
            })
            .catch((err) => {
                console.error(err);
                setLoadinNa(false);
            });
    };

    const selectedNacionality = (code, name) => {
        setNacionality(name);
    };

    const handleFormOpen = (index) => {
        setActiveFormIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const validateForm = () => {
        let valid = true;
        const errors = {};

        formsFilter.forEach(camps => {
            const procedimiento = camps.procedimiento;

            // Verify required images
            if (camps?.require_images?.length > 0) {
                const uploadedImages = formData[procedimiento]?.require_images || {};
                const uploadedCount = Object.keys(uploadedImages).length; // Count how many images have been uploaded

                if (uploadedCount < camps.require_images.length) {
                    valid = false;
                    errors[procedimiento] = {
                        ...errors[procedimiento],
                        require_images: {
                            message: `Para continuar, debes subir ${camps.require_images.length} imágenes.`
                        }
                    };
                }
            }

            camps.formulario.forEach(campo => {
                const value = formData[procedimiento]?.[campo.key];

                // Verify if the field depends on another
                const isDisabled = campo.dependsOn
                    ? formData[procedimiento]?.[campo.dependsOn.key] !== campo.dependsOn.value
                    : false;

                if (isDisabled) {
                    return; // Go to the next field without validation
                }

                // If the field is required and empty, mark error
                if (campo.required && !value) {
                    valid = false;
                    errors[procedimiento] = {
                        ...errors[procedimiento],
                        [campo.key]: 'Este campo es obligatorio'
                    };
                }
            });
        });
        setFormErrors(errors);
        return valid;
    };

    const [uploadedImages, setUploadedImages] = useState({});

    const handleImageUpload = (e, procedimiento, index) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64Image = reader.result;// base64 of the image

                // Update the status of the uploaded images (for the previous view, you can continue using the temporary URL)
                setUploadedImages(prevState => ({
                    ...prevState,
                    [procedimiento]: {
                        ...prevState[procedimiento],
                        [index]: URL.createObjectURL(file),// Save the temporary URL for previous view
                    }
                }));

                // Update formdata with the image in base format64
                setFormData(prevData => ({
                    ...prevData,
                    [procedimiento]: {
                        ...prevData[procedimiento],
                        require_images: {
                            ...prevData[procedimiento]?.require_images,
                            [index]: base64Image // Save the image based64
                        }
                    }
                }));

                // Clean errors if the image was up correctly
                setFormErrors(prevErrors => {
                    const newErrors = { ...prevErrors };

                    // If there was an error in the image, eliminate it
                    if (newErrors[procedimiento]?.require_images?.[index]) {
                        delete newErrors[procedimiento].require_images[index];

                        // If there are no image errors, eliminate the property 'require_images'
                        if (Object.keys(newErrors[procedimiento].require_images).length === 0) {
                            delete newErrors[procedimiento].require_images;
                        }

                        // If the procedure no longer has errors, eliminate the entry of the procedure
                        if (Object.keys(newErrors[procedimiento]).length === 0) {
                            delete newErrors[procedimiento];
                        }
                    }

                    return newErrors;
                });
            };

            // Read the file as a base64
            reader.readAsDataURL(file);
        }
    };

    const [selectedOperationDate, setSelectedOperationDate] = useState(null);
    const calendarRef = useRef();

    useEffect(() => {
        // Verify if there is any error in the forms
        const formWithErrorIndex = formsFilter.findIndex(camps => {
            const procedimiento = camps?.procedimiento;
            const formErrorsForProcedure = FormErrors[procedimiento];

            // If the procedure has errors, return the index
            return formErrorsForProcedure && Object.keys(formErrorsForProcedure).some(key => formErrorsForProcedure[key]);
        });

        // If an errors form was found, we update the state to open that form
        if (formWithErrorIndex !== -1) {
            setActiveFormIndex(formWithErrorIndex);
        }
    }, [FormErrors, formsFilter]);



    return (
        <>
            <Toaster position="top-center" reverseOrder={false} />
            {
                loading &&
                <Loading />
            }
            <SessionModal activeSessionModal={activeSession} setActiveSessionModal={setActiveSession} />
            <ModalAction open={open} setOpen={closeModal} />
            <CarouselSevices />
            <div className={styles.layoutMax}>
                {
                    especialtieAvliable && (
                        <>
                            <div className={styles.itemSectionAll}>
                                <StepHome />
                                {dataStepzTitles.map((item, index) => (
                                    <StepStep
                                        key={index}
                                        title={item}
                                        step={index + 1}
                                        onClick={handleStepClick}
                                        dataPackage={dataPackage}
                                    />
                                ))}
                            </div>

                            <DataSearch />
                        </>
                    )
                }
                <div className={styles.layout}>
                    <form action="" className={styles.form}>
                        <div className={styles.formTitle}>
                            <legend>Formulario STD</legend>
                            <p>Sistema de Triage y Diagnostico</p>
                        </div>


                        <div className={styles.formSeparationInfo}>
                            <div className={styles.formSeparationItem}>

                                <div className={styles.formTitleItem}>
                                    <h4>1. Datos Personales</h4>
                                    <p>Llena tus datos personales si aún no lo has hecho.</p>
                                </div>

                                <div className={styles.formItemsData}>
                                    <div className={styles['camp-two-check-column']}>
                                        <p>
                                            <span>Sr / Sra: </span>
                                            {userDetail?.name}
                                        </p>
                                        <p>
                                            <span>Correo: </span>
                                            {userDetail?.email}
                                        </p>
                                        <p>
                                            <span>Fecha de nacimiento: </span>
                                            {userDetail?.birth_date && formatYYYYMMDDToDDMMYYYY(userDetail?.birth_date)}
                                        </p>
                                        <p>
                                            <span>Celular: +</span>{userDetail?.prex_phone_public} {userDetail?.phone_public}
                                        </p>
                                    </div>



                                    <div className={styles.formTitleItemAnyDescription}>
                                        <p>Información adicional</p>
                                    </div>


                                    <div className={styles["camp-one"]}>
                                        <InputNacionalityStd
                                            nacionalityDropdown={nacionalityDropdown}
                                            setNacionalityDropdown={setNacionalityDropdown}
                                            nacionality={nacionality}
                                            validationForm={validation}
                                            paceholders={paceholders}
                                            findCountryClient={findCountryClient}
                                            loading={loadinNa}
                                            dataNacionality={dataNacionality}
                                            selectedNacionality={selectedNacionality}
                                        />

                                    </div>
                                    <div className={styles["camp-two"]} ref={calendarRef}>
                                        {/* Fecha de Operación */}
                                        <div className={styles.right}>
                                            <label htmlFor="operationDate">Fecha que deseas operarte<span style={{color:'red'}}> *</span></label>
                                            <DateInputWithPicker
                                                inputId="operationDate"
                                                selectedDate={selectedOperationDate}
                                                setSelectedDate={setSelectedOperationDate}
                                                setOperationDate={setOperationDate}
                                                isBirthday={false}
                                                placeholder="dd/mm/yyyy"
                                                icon={() => <LuCalendarPlus color="var(--blueLightTransparent)" size={17} />}
                                                hasButton={false}
                                                validationForm={validation}
                                            />
                                        </div>
                                    </div>

                                    <div className={styles["camp-three"]}>
                                        <div className={styles.center}>
                                            <label htmlFor="">Estatura<span style={{color:'red'}}> *</span></label>
                                            <input type="number" placeholder='PJ 1.60' className={!height && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                                setHeight(e.target.value)
                                            }} />
                                        </div>
                                        <div className={styles.right}>
                                            <label htmlFor="">Peso<span style={{color:'red'}}> *</span></label>
                                            <input type="number" placeholder='PJ 60Kg' className={!weight && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                                setWeight(e.target.value)
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.formSeparationItem}>
                                <div className={styles.formTitleItemAnyDescription}>
                                    <p>Preguntas importantes</p>
                                </div>

                                <div className={styles.formItemsCheckboxes}>
                                    <div className={styles['camp-two-check-column']}>
                                        <div className={styles.divOptions}>
                                            <span>
                                                Sí
                                            </span>
                                            <span>
                                                No
                                            </span>
                                        </div>
                                        <div className={styles.checkboxCont}>
                                            <div className={styles.divcheck}>
                                                <input
                                                    type="radio"
                                                    id="drinkYes"
                                                    name="drinkAlcohol"
                                                    checked={drinkAlcohol === true}
                                                    onChange={() => {
                                                        setDrinkAlcohol(true);
                                                    }}
                                                />
                                            </div>
                                            <div className={styles.divcheck}>
                                                <input
                                                    type="radio"
                                                    id="drinkNo"
                                                    name="drinkAlcohol"
                                                    checked={drinkAlcohol === false}
                                                    onChange={() => {
                                                        setDrinkAlcohol(false);
                                                    }}
                                                />
                                            </div>
                                            <label htmlFor="">¿Consume alcohol?</label>
                                        </div>
                                    </div>

                                    <div className={styles['camp-two-check-column']}>
                                        <div className={styles.checkboxCont}>
                                            <div className={styles.divcheck}>
                                                <input
                                                    type="radio"
                                                    id="smokeYes"
                                                    name="smoke"
                                                    checked={smoke === true}
                                                    onChange={() => {
                                                        setSmoke(true);
                                                    }}
                                                />
                                            </div>
                                            <div className={styles.divcheck}>
                                                <input
                                                    type="radio"
                                                    id="smokeNo"
                                                    name="smoke"
                                                    checked={smoke === false}
                                                    onChange={() => {
                                                        setSmoke(false);
                                                    }}
                                                />
                                            </div>
                                            <label htmlFor="">¿Fuma?</label>
                                        </div>
                                    </div>

                                    <div className={styles['camp-two-check-middle']}>
                                        <div className={styles.checkboxCont}>
                                            <div className={styles.divcheck}>
                                                <input
                                                    type="radio"
                                                    id="sonsYes"
                                                    name="sons"
                                                    checked={sons === true}
                                                    onChange={() => {
                                                        setSons(true);
                                                        setQuantityChild("");
                                                        setAges([]);
                                                    }}
                                                />
                                            </div>
                                            <div className={styles.divcheck}>
                                                <input
                                                    type="radio"
                                                    id="sonsNo"
                                                    name="sons"
                                                    checked={sons === false}
                                                    onChange={() => {
                                                        setSons(false);
                                                        setQuantityChild("");
                                                        setAges([]);
                                                    }}
                                                />
                                            </div>
                                            <label htmlFor="sons">¿Tienes hijos?</label>
                                        </div>

                                        {/* Renderiza el campo para cantidad de hijos si se selecciona "Sí" */}
                                        {sons && (
                                            <div className={styles['camp-middle']}>
                                                <label htmlFor="quantityChild">¿Cuántos hijos tienes? *</label>
                                                <input
                                                    type="number"
                                                    id="quantityChild"
                                                    min="1"
                                                    max="30"
                                                    placeholder="Ingresa la cantidad de hijos"
                                                    className={!quantityChild && validation === true ? styles.obligatory : ""}
                                                    value={quantityChild}
                                                    onChange={(e) => {
                                                        let count = parseInt(e.target.value, 10);

                                                        if (count > 30) count = 30;
                                                        if (count < 1) count = 1;

                                                        setQuantityChild(count);

                                                        setAges((prevAges) => {
                                                            if (count > prevAges.length) {
                                                                return [...prevAges, ...Array(count - prevAges.length).fill("")];
                                                            } else {
                                                                return prevAges.slice(0, count);
                                                            }
                                                        });
                                                    }}
                                                />
                                            </div>
                                        )}

                                        {/* Renderiza los inputs de edades si está expandido */}
                                        {sons && quantityChild > 0 && (
                                        <div
                                            className={`relative max-h-52 overflow-y-scroll w-[48%] border border-gray-300 p-4 rounded-lg bg-gray-50 ${styles.customScroll}`}
                                        >
                                            {/* Botón para expandir/colapsar */}
                                            <button
                                                type="button"
                                                className="absolute top-2 right-2 text-sm text-blue-500 underline focus:outline-none"
                                                onClick={() => setShowAges(!showAges)}
                                            >
                                                {showAges ? 
                                                    <button className={`${styles.buttonArrow} ${styles.buttonArrowOpen}`} type="button">
                                                        <BiSolidRightArrow size={20} />
                                                    </button>
                                                    :
                                                    <div className="flex gap-2">
                                                        <span>
                                                            Mostrar
                                                        </span>
                                                        <button className={styles.buttonArrow} type="button">
                                                            <BiSolidRightArrow size={20} />
                                                        </button>  
                                                    </div>
                                                }
                                            </button>

                                            {showAges && (
                                                <>
                                                    <label htmlFor="ages" className="block text-sm font-semibold mb-2">
                                                        Ingresa las edades en años <span className="text-red-500">*</span>
                                                    </label>
                                                    {ages.map((age, index) => (
                                                        <div key={index} className="mt-5 mb-4">
                                                            <label
                                                                htmlFor={`age-${index + 1}`}
                                                                className="block text-sm font-medium text-gray-700 mb-2 w-fit"
                                                            >
                                                                Hijo {index + 1}:
                                                            </label>
                                                            <input
                                                                type="number"
                                                                id={`age-${index + 1}`}
                                                                placeholder={`Edad del hijo ${index + 1}`}
                                                                value={age}
                                                                min="1"
                                                                max="100"
                                                                className={`mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                                                                    !age && validation === true ? "border-red-500" : ""
                                                                }`}
                                                                onChange={(e) => {
                                                                    let count = parseInt(e.target.value, 10);

                                                                    if (count > 100) count = 100;
                                                                    if (count < 1) count = 1;

                                                                    setAges((prevAges) => {
                                                                        const newAges = [...prevAges];
                                                                        newAges[index] = count;
                                                                        return newAges;
                                                                    });
                                                                }}
                                                            />
                                                            {!age && (
                                                                <p className="text-red-500 text-[12px] font-[400]">
                                                                    Debes ingresar las edades de todos los hijos
                                                                </p>
                                                            )}
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        </div>
                                    )}
                                    </div>


                                    <div className={styles['camp-two-check-middle']}>
                                        <div className={styles.checkboxCont}>
                                            <div className={styles.divcheck}>
                                                <input
                                                    type="radio"
                                                    id="haveSurgeryYes"
                                                    name="haveSurgery"
                                                    checked={haveSurgery === true}
                                                    onChange={() => {
                                                        setHaveSurgery(true);
                                                    }}
                                                />
                                            </div>
                                            <div className={styles.divcheck}>
                                                <input
                                                    type="radio"
                                                    id="haveSurgeryNo"
                                                    name="haveSurgery"
                                                    checked={haveSurgery === false}
                                                    onChange={() => {
                                                        setHaveSurgery(false);
                                                    }}
                                                />
                                            </div>
                                            <label htmlFor="">¿Se ha practicado alguna cirugia?</label>
                                        </div>
                                        {
                                            haveSurgery &&
                                            <div className={styles['camp-middle']}>
                                                <label htmlFor="">Cirugias Anteriores *</label>
                                                <input type="text" placeholder='PJ Liposuccion' className={!whichSurgery && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                                    setWhichSurgery(e.target.value)
                                                }} />
                                            </div>
                                        }

                                    </div>
                                    <div className={styles['camp-two-check-middle']}>
                                        <div className={styles.checkboxCont}>
                                            <div className={styles.divcheck}>
                                                <input
                                                    type="radio"
                                                    id="haveIllnessYes"
                                                    name="haveIllness"
                                                    checked={haveIllness === true}
                                                    onChange={() => {
                                                        setHaveIllness(true);
                                                    }}
                                                />
                                            </div>
                                            <div className={styles.divcheck}>
                                                <input
                                                    type="radio"
                                                    id="haveIllnessNo"
                                                    name="haveIllness"
                                                    checked={haveIllness === false}
                                                    onChange={() => {
                                                        setHaveIllness(false);
                                                    }}
                                                />
                                            </div>
                                            <label htmlFor="">¿Sufre alguna enfermedad importante?</label>
                                        </div>
                                        {
                                            haveIllness &&
                                            <div className={styles['camp-middle']}>
                                                <label htmlFor="">¿Cuales? *</label>
                                                <input type="text" className={!whichIllness && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                                    setWhichIllness(e.target.value)
                                                }} />
                                            </div>
                                        }

                                    </div>

                                    <div className={styles['camp-two-check-middle']}>
                                        <div className={styles.checkboxCont}>
                                            <div className={styles.divcheck}>
                                                <input
                                                    type="radio"
                                                    id="drugsConsumeYes"
                                                    name="drugsConsume"
                                                    checked={drugsConsume === true}
                                                    onChange={() => {
                                                        setDrugsConsume(true);
                                                    }}
                                                />
                                            </div>
                                            <div className={styles.divcheck}>
                                                <input
                                                    type="radio"
                                                    id="drugsConsumeNo"
                                                    name="drugsConsume"
                                                    checked={drugsConsume === false}
                                                    onChange={() => {
                                                        setDrugsConsume(false);
                                                    }}
                                                />
                                            </div>
                                            <label htmlFor="">¿Consume algun estupefaciente?</label>
                                        </div>
                                        {
                                            drugsConsume &&
                                            <div className={styles['camp-middle']}>
                                                <label htmlFor="">¿Cuales? *</label>
                                                <input type="text" className={!whichDrugs && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                                    setWhichDrugs(e.target.value)
                                                }} />
                                            </div>
                                        }

                                    </div>
                                    <div className={styles['camp-two-check-middle']}>
                                        <div className={styles.checkboxCont}>
                                            <div className={styles.divcheck}>
                                                <input
                                                    type="radio"
                                                    id="consumeMedicamentsYes"
                                                    name="consumeMedicaments"
                                                    checked={consumeMedicaments === true}
                                                    onChange={() => {
                                                        setConsumeMedicaments(true);
                                                    }}
                                                />
                                            </div>
                                            <div className={styles.divcheck}>
                                                <input
                                                    type="radio"
                                                    id="consumeMedicamentsNo"
                                                    name="consumeMedicaments"
                                                    checked={consumeMedicaments === false}
                                                    onChange={() => {
                                                        setConsumeMedicaments(false);
                                                    }}
                                                />
                                            </div>
                                            <label htmlFor="">¿Tomas algun medicamento?</label>
                                        </div>
                                        {
                                            consumeMedicaments &&
                                            <div className={styles['camp-middle']}>
                                                <label htmlFor="">Medicamentos que toma *</label>
                                                <input type="text" className={!whichMedicaments && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                                    setWhichMedicaments(e.target.value)
                                                }} />
                                            </div>
                                        }

                                    </div>

                                    <div className={styles['camp-two-check-middle']}>
                                        <div className={styles.checkboxCont}>
                                            <div className={styles.divcheck}>
                                                <input
                                                    type="radio"
                                                    id="youAlergicKnowedYes"
                                                    name="youAlergicKnowed"
                                                    checked={youAlergicKnowed === true}
                                                    onChange={() => {
                                                        setYouAlergicKnowed(true);
                                                    }}
                                                />
                                            </div>
                                            <div className={styles.divcheck}>
                                                <input
                                                    type="radio"
                                                    id="youAlergicKnowedNo"
                                                    name="youAlergicKnowed"
                                                    checked={youAlergicKnowed === false}
                                                    onChange={() => {
                                                        setYouAlergicKnowed(false);
                                                    }}
                                                />
                                            </div>
                                            <label htmlFor="">¿Tienes alguna alergia conocida?</label>
                                        </div>
                                        {
                                            youAlergicKnowed &&
                                            <div className={styles['camp-middle']}>
                                                <label htmlFor="">¿A qué? *</label>
                                                <input type="text" className={!whichAlergicKnowed && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                                    setWhichAlergicKnowed(e.target.value)
                                                }} />
                                            </div>
                                        }
                                    </div>

                                    <div className={styles['camp-two-check-middle']}>
                                        <div className={styles.checkboxCont}>
                                            <div className={styles.divcheck}>
                                                <input
                                                    type="radio"
                                                    id="youAlergicYes"
                                                    name="youAlergic"
                                                    checked={youAlergic === true}
                                                    onChange={() => {
                                                        setYouAlergic(true);
                                                    }}
                                                />
                                            </div>
                                            <div className={styles.divcheck}>
                                                <input
                                                    type="radio"
                                                    id="youAlergicNo"
                                                    name="youAlergic"
                                                    checked={youAlergic === false}
                                                    onChange={() => {
                                                        setYouAlergic(false);
                                                    }}
                                                />
                                            </div>
                                            <label htmlFor="">¿Es alergico algun medicamento o sutura?</label>
                                        </div>
                                        {
                                            youAlergic &&
                                            <div className={styles['camp-middle']}>
                                                <label htmlFor="">¿Cuales? *</label>
                                                <input className={!whichAlergic && validation === true ? styles.obligatory : ""} type="text" onChange={(e) => {
                                                    setWhichAlergic(e.target.value)
                                                }} />
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                    <div className={styles.previewDate}>
                        <section className={styles.contPrev}>

                            {
                                formsFilter.length > 0 ?
                                    formsFilter.map((item, index) => {
                                        return (
                                            <FormProcedure
                                                key={index}
                                                camps={item}
                                                change={manejarCambio}
                                                setFormData={setFormData}
                                                isOpen={activeFormIndex === index}
                                                onOpen={(index3) => handleFormOpen(index3)}
                                                formError={FormErrors}
                                                formData={formData}
                                                index={index}
                                                formsFilter={formsFilter}
                                                FormErrors={FormErrors}
                                                uploadedImages={uploadedImages}
                                                handleImageUpload={handleImageUpload}
                                                validateForm={validateForm}
                                                setCheckboxCheck={setCheckboxCheck}
                                                checkboxCheck={checkboxCheck}
                                            />
                                        )
                                    })
                                    :
                                    <p>
                                        No hay forms
                                    </p>
                            }
                        </section>


                        <div className={styles.contContinue}>
                            <div className={styles.contNote}>
                                <span>Nota:</span>
                                <p>
                                    Para continuar debes de llenar todos los datos del formulario, <br /> para facilitar tu valoración con nuestro sistema del STD
                                </p>
                            </div>

                            <div className={styles.checkTerms}>
                                <input type="checkbox" id='confirm' className={!terms && validation === true ? styles.obligatory : ""}
                                    checked={terms}
                                    onChange={() => {
                                        setTerms(!terms)
                                    }}
                                />
                                <label htmlFor='confirm'>
                                    He leído y acepto las <span>Politicas de Tratamientos de Datos Personales</span>
                                </label>
                            </div>


                            <button
                                className={styles.btnContinue}
                                disabled={!terms}
                                style={{ opacity: !terms  ? "0.5" : "1" }}
                                onClick={sendStd}
                            >
                                Continuar
                            </button> 
                        </div>
                    </div>
                </div>
            </div>
            <AnyQuestions title={t("browse_home.have_doubt")} />
        </>
    )
}

export default Std;

import "./styles.css";
import { ReactComponent as IconPlane2 } from '../../../../../Components/SuraDataPassengersDetail/SuraEditFormQuote/Assets/svg/IconPlane2.svg';
import { ReactComponent as IconDoctor } from '../../../../../Components/SuraDataPassengersDetail/SuraEditFormQuote/Assets/svg/IconDoctor.svg';
import { ReactComponent as IconHospital } from '../../../../../Components/SuraDataPassengersDetail/SuraEditFormQuote/Assets/svg/IconHospital.svg';
import { ReactComponent as IconHotel } from '../../../../../Components/SuraDataPassengersDetail/SuraEditFormQuote/Assets/svg/IconHotel.svg';
import { GrFormAdd } from "react-icons/gr";

function BrowseHome({ pathname }) {

  return ( 
    <section className="browseHomeColumn">
      <div className="divBanner">
        <img src="/images/bannerHome.png" alt="wellezy clinic" className="bannerImg bannerImg-desktop" />
        <img src="/images/BannerResponsive.png" alt="wellezy clinic" className="bannerImg bannerImg-mobile" />

        <div className="textOverlay">
          <div className="textOverlayTop">
            <div className="textOverlayTitle">
              <h2 className="titleOverlay1">ARMA</h2>
              <h2 className="titleOverlay2">TU PAQUETE</h2>
              <h3>DE TURISMO MÉDICO A TU MEDIDA</h3>
            </div>
          </div>


          <div className="contentMidOverlay">
            Vive la experiencia completa
          </div>

          <div className="divIccons">
            <div>
              <IconPlane2 className="iconDivHome vuelosIconDiv" />
              <p>Vuelos</p>
            </div>
            <span><GrFormAdd className="iconSumHome" /></span>
            <div>
              <IconHotel className="iconDivHome hotelIconDiv" />
              <p>Alojamiento</p>
            </div>
            <span><GrFormAdd className="iconSumHome" /></span>
            <div>
              <IconDoctor className="iconDivHome doctoIconDiv" />
              <p>Especialista</p>
            </div>
            <span><GrFormAdd className="iconSumHome" /></span>
            <div>
              <IconHospital className="iconDivHome clinicaIconDiv" />
              <p>Clinicas</p>
            </div>
          </div>

          <div className="mainIconsDiv">
            <button>
              <a href="/build-package">
                Arma tu Paquete
              </a>
            </button>

            <p>Viaja seguro junto a una de las mejores agencias de turismo médico en Colombia.</p>
          </div>

        </div>
      </div>
    </section>
  );
}
export default BrowseHome;

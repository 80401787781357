import "./styles.css";
import img1 from "./img1.png";
import img2 from "./img2.png";
import img3 from "./img3.png";
import img4 from "./img4.png";
import SliderCard from "../../home/components/SliderOferts/SliderCard";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";

const BestExperiencesTop = () => {
    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [CardsType, setCardsType] = useState([]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1354,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 950,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const cards = [
        {
            img: img1,
            title: "Implantes mamarios en medellín",
            description: t("browse_home.cards_slider.card_1.description"),
            price: t("browse_home.cards_slider.card_1.price"),
        },
        {
            img: img2,
            title: "Diseño de sonrisa en Bogota",
            description: t("browse_home.cards_slider.card_1.description"),
            price: t("browse_home.cards_slider.card_1.price"),
        },
        {
            img: img3,
            title: "Tratamiento de fertilidad FIV e...",
            description: t("browse_home.cards_slider.card_1.description"),
            price: t("browse_home.cards_slider.card_1.price"),
        },
        {
            img: img4,
            title: "Cirugía de perdidade de peso...",
            description: t("browse_home.cards_slider.card_1.description"),
            price: t("browse_home.cards_slider.card_1.price"),
        },
    ];

    const searchTypeCards = () => {
        const url = `${process.env.REACT_APP_URL_API_AIOP}api/medic_packages`;
        axios
            .get(url)
            .then((res) => {
                setCardsType(res.data?.packs.data);
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        setLoading(true);
        searchTypeCards();
    }, []);

    const detailPack = (item, id) => {
        navigate(`/TourismHealth/DetailProcedure/${id}`, {
            state: {
                data: item,
            },
        });
    };

    const [loadingImage, setLoadingImage] = useState(true);

    const handleClickPack = (item, id) => {
        navigate(`/packages/${id}`);
    };

    return (
        <div className="content-best-experiences">
            <div className="header-experiences2">
                <h2>PAQUETES MÉDICOS</h2>
            </div>
            <section className="slider-experience">
                <Slider {...settings}>
                    {CardsType.map((item, key) => {
                        return (
                            <SliderCard
                                carousel={true}
                                boxShadow={true}
                                img={item.image}
                                imgOption={true}
                                price={item.price}
                                key={key}
                                title={item.name}
                                typeCurrency={item?.currency}
                                item={item}
                                loadingImage={loadingImage}
                                setLoadingImage={setLoadingImage}
                                colorButton={"var(--blueLight)"}
                                startDate={
                                    item?.start_date ? item?.start_date : item?.trip_from
                                }
                                dueDate={item?.due_date ? item?.due_date : item?.trip_up}
                                typePack={item?.due_date ? false : true}
                                handleClickPack={item?.due_date ? handleClickPack : detailPack}
                            />
                        );
                    })}
                </Slider>
            </section>
        </div>
    );
};

export default BestExperiencesTop;

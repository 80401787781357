import { Grid } from '@mui/material';
import styles from "./index.module.css";

const Clinic = ({ dataPackage }) => { 

    return (
        <div className={styles.mainClinicDivPackage} >
            <div className={styles.topClinicDivPackage}>
                <h3>{dataPackage.clinic?.name}</h3>
                <h4>{dataPackage.clinic?.address}</h4>
            </div> 
            <div className={styles.descClinicPackage}>
                <p>{dataPackage.clinic?.description}</p>
            </div> 
        </div>
    );
};

export default Clinic;
export const parseDDMMYYYYToDate = (dateString) => {
    try {
        if (typeof dateString !== "string") {
            console.error("parseDDMMYYYYToDate: dateString is not a string", dateString);
            return null;
        }
        const [day, month, year] = dateString.split("/").map(Number);
        return new Date(year, month - 1, day); // Month is 0-indexed
    } catch (error) {
        console.error("Error parsing date:", error);
        return null;
    }
};

export const formatToISO = (dateString) => {
    try {
        const date = parseDDMMYYYYToDate(dateString);
        if (!date || isNaN(date)) {
            console.error("formatToISO: invalid date", dateString);
            return null;
        }
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    } catch (error) {
        console.error("Error formatting to ISO:", error);
        return null;
    }
};

export const formatDateToISO = (date) => {
    try {
        if (!date || isNaN(date)) {
            console.error("formatDateToISO: invalid date", date);
            return null;
        }
        
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    } catch (error) {
        console.error("Error formatting to ISO:", error);
        return null;
    }
}

export const formatToDDMMYYYY = (date) => {
    try {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${day}/${month}/${year}`;
    } catch (error) {
        console.error("Error parsing date:", error);
        return date;
    }
};

export const formatYYYYMMDDToDDMMYYYY = (dateString) => {
    try {

        const [year, month, day] = dateString.split("-") || dateString.split("/");
        return `${day}/${month}/${year}`;
    } catch (error) {
        console.error("Error parsing date:", error);
        return dateString;
    }
};

export const parseYYYYMMDDToDate = (dateString) => {
    try {
        const [year, month, day] = dateString.split("-").map(Number);
        return new Date(year, month - 1, day); // Restamos 1 al mes porque es 0-indexado en JavaScript
    } catch (error) {
        console.error("Error parsing YYYY-MM-DD to Date:", error);
        return null; // Devuelve null si ocurre un error
    }
};
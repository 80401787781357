import React, { useEffect, useState } from "react";
import CarouselSevices from "../../../share/components/CarouselServices/CarouselSevices";
import SearchBuildPackage from "../../components/SearchBuildPackage/SearchBuildPackage";
import StepStep from "../../components/Step/StepStep";
import TransportResultsSearch from "@/Routes/TransportRoute/TransportResultsSearch";

import { useDispatch, useSelector } from "react-redux";
import { setCurrentStep } from "@/store/slices/dataStepz/dataStepzSlice";

const StepTransport = () => {
  const dispatch = useDispatch();

  const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
  const setSpetVariableData = useSelector((state) => state.dataSearchEngine.spetVariableData);

  const [activeStep, setActiveStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([1]);
  const [dataStepzTitles, setDataStepzTitles] = useState([]);

  const handleStepClick = (step) => {
    if (step <= activeStep + 1) {
      setActiveStep(step);
      if (!completedSteps.includes(step)) {
        setCompletedSteps([...completedSteps, step]);
      }
    }
  };

  useEffect(() => {
    dispatch(setCurrentStep(5));
  }, []);

  useEffect(() => {
    let titles = [];
    if (setSpetVariableData === 0) {
      titles = [ "Especialista", "Clínica","Hotel", "Resumen", "STD"];
    } else if (setSpetVariableData === 1) {
      titles = [ "Especialista", "Clínica", "Vuelo", "Resumen", "STD"];
    } else if (setSpetVariableData === 2) {
      titles = [ "Especialista", "Clínica", "Hotel", "Vuelo", "Resumen", "STD"];
    } else if (setSpetVariableData === 3) {
      titles = ["Especialista", "Clínica", "Vuelo", "Hotel", "Traslado","Resumen", "STD"];
    }
    setDataStepzTitles(titles);
  }, [setSpetVariableData]);

  return (
    <div>
      <section className="homeSectionCarrousel">
        <CarouselSevices />
      </section>
      <SearchBuildPackage />

      <div className="maxWidthBuildPackage">
        <div className="itemSectionAll">
          {dataStepzTitles.map((item, index) => (
              <StepStep
                key={index}
                title={item}
                step={index + 1}
                onClick={handleStepClick}
                dataPackage={dataPackage}
              />
            ))}
        </div>

        <TransportResultsSearch />
      </div>
    </div>
  );
};

export default StepTransport;

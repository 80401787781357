import React, { useEffect, useState } from "react";
import "./styles.css";
import SvgIcon, { iconList } from "../../share/components/iconSVG";
import Loading from "../../share/components/loading";
import { useTranslation } from "react-i18next";
import CardSpecialist from "./CardSpecialist/CardSpecialist";
import styles from "./styles.module.css"
import UseSpecialist from "@/Hooks/BuildPackage/useSpecialist/UseSpecialist";
import { FiAlertCircle } from "react-icons/fi";
 

const AllCardsSpecialist = ({ data, urlImg, loading, detailSpecialist, seeButton,
  findSpecialist,
  setCity,
  setName,
  city,
  name,
  setModal,
  seeFilter,
  specialist
}) => {

  const {
    nextStep
  } = UseSpecialist()

  return (
    <>
      {loading === false && data?.length === 0 && (
        <div className={styles.gridNotResults}>
          <h2>No hay resultados <FiAlertCircle /></h2>

          <button onClick={() => {
            setName("");
            if (!seeFilter) {
              setCity("");
            }
            setModal(false);
            findSpecialist(1, "", !seeFilter ? "" : city, specialist);
          }} className={styles.returnSearch}>
            Volver a buscar
          </button>
        </div>
      )}

      <div className={styles.grid_container_doc}>
        {loading === true ? (
          <Loading size={100} />
        ) : (
          data?.map((item, key) => {
            return (
              <CardSpecialist
                key={key}
                data={item}
                detailDoctor={detailSpecialist}
                seeButton={seeButton}
                nextStep={nextStep}
              />
            )
          }
          )
        )}
      </div>
    </>


  );
};

export default AllCardsSpecialist;

import { Grid } from '@mui/material';
import styles from "./index.module.css";
import { IoIosAdd } from 'react-icons/io';

const formatDate = (dateString) => {
    if (!dateString) return '';
    const [month, day, year] = dateString.split('/');
    return `${month}/${day}/${year.slice(-2)}`;
};

const Specialist = ({ dataPackage }) => {

    const specialties = dataPackage?.specialist?.specialties || [];


    return (
        <div className={styles.mainClinicDivPackage}>
            <div className={styles.topClinicDivPackage}>
                <h3>{dataPackage.specialist?.name}</h3>
                <h4>{dataPackage.specialist?.categories[0].name}</h4>
            </div>

            <div className={styles.infoSpecialistPackage}>

                <div className={styles.descriptionSpecialist}>
                    <div className={styles.textDescript} >
                        <p>{dataPackage.specialist?.description}</p>
                    </div>

                    <div className={styles.dataDesctip} >
                        <h3>Procedimiento</h3>
                        <p>
                            {specialties?.map((item, index) => item.specialty).join(', ')}
                        </p>
                    </div>
                </div>

                <div className={styles.bottomSpecialist} >
                    <div className={styles.dataDesctip} >
                        <h3>Fecha</h3>
                        <p>{formatDate(dataPackage?.hotel?.arrival)}</p>
                    </div>

                    <button>
                        <IoIosAdd />
                        Agregar procedimiento
                    </button>
                </div>

            </div>

        </div>
    );
};

export default Specialist;